<!-- 门店经营报表 -->
<template>
  <section class="cont storeOperatingReport">
    <Breadcrumb :crumbs="crumbs"/>

    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button class="bg-gradient" type="primary" @click="back"><span>返回</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column type="index" width="60" label="序号"></el-table-column>
          <el-table-column prop="hotelName" label="门店名称" min-width="30"></el-table-column>
          <el-table-column label="门店销售额" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.totalMoney.toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="门店实际收入" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.incomeMoney.toFixed(2)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="orderCount" label="门店订单数" min-width="30"></el-table-column>
          <el-table-column prop="roomNum" label="门店销售房间数" min-width="30"></el-table-column>
          <el-table-column prop="userNum" label="购买人数" min-width="30"></el-table-column>
          <el-table-column label="平均房价" min-width="30">
            <template slot-scope="scope">
              <div>￥{{ scope.row.averagePrice.toFixed(2)}}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import {mapState} from "vuex";
import { getGroupRoomSituationInfo } from "@/api/GroupManagement/groupManagement";
export default {
  data(){
    return{
      crumbs: new Map([
        ['集团管理'], ['统计报表'], ['集团客房经营报表'],['查看详情']
      ]),
      tableData:[],        // 表格数据
      loading: true,       // 表格加载效果
      total: 0,            // 用户列表总条目数
      page: 1,             // 当前页 默认第一页
      limit:10,            // 每页显示数
    }
  },
  computed:{
    ...mapState(['hotelInfo']),
  },
  mounted(){
    this.getData();
  },
  methods:{
    // 请求表格数据
    getData(){
      let data = {
        limit:this.limit,
        page:this.page,
        date:this.$route.params.date,
        storeId:this.hotelInfo.storeId         // 集团ID
      }
      getGroupRoomSituationInfo(data).then(({ success, records, total }) => {
        if (success) {
          this.total = total;
          this.tableData = records;
          this.loading = false;
        }
      })
    },
    // 返回
    back(){
      this.$router.push({path: `/group/roomOperationReport`})
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
  }
}
</script>