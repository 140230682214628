import request from "@/api/service";
import qs from 'qs'

const api = {
  // 集团客房经营
  getGroupRoomSituation: '/order/getGroupRoomSituation',
  // 集团客房经营详情
  getGroupRoomSituationInfo: '/order/getGroupRoomSituationInfo',
  // 集团客房经营导出
  exportGroupRoomSituation: '/order/exportGroupRoomSituation',
  
  // 集团预售券经营
  getCompanyStatisticsCouponMyself:'/event/couponMyself/getCompanyStatisticsCouponMyself',
  // 集团预售券经营详情
  getHotelStatisticsCouponMyself: '/event/couponMyself/getHotelStatisticsCouponMyself',
  // 集团预售券经营导出
  exportCoupon:'/event/couponMyself/statisticsCompanyCouponMyselfExportExcel',
}

// 集团客房经营
export function getGroupRoomSituation(params) {
   return request({
      url: api.getGroupRoomSituation,
      method: 'GET',
      params
   })
}

// 集团客房经营详情
export function getGroupRoomSituationInfo(params) {
   return request({
      url: api.getGroupRoomSituationInfo,
      method: 'GET',
      params
   })
}

// 集团客房经营导出
export function exportGroupRoomSituation(params) {
  return request({
     url: api.exportGroupRoomSituation,
     method: 'GET',
     params,
     responseType: 'blob'
  })
}

// 集团预售券经营
export function getCompanyStatisticsCouponMyself(params,data) {
   return request({
      url: api.getCompanyStatisticsCouponMyself,
      headers:{"responseType": 'JSON'},
      method: 'post',
      params,
      data:qs.stringify(data)
   })
}

// 集团客房经营详情
export function getHotelStatisticsCouponMyself(params,data) {
   return request({
      url: api.getHotelStatisticsCouponMyself,
      headers:{"responseType": 'JSON'},
      method: 'post',
      params,
      data:qs.stringify(data)
   })
}

// 集团预售券经营导出
export function exportCoupon(params) {
   return request({
      url: api.exportCoupon,
      method: 'GET',
      params,
      responseType: 'blob'
   })
 }